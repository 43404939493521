export default {
  // en
  '0': {
    loading: 'Loading...',
    title: 'Survey',
    next: 'Next',
    prev: 'Prev',
    mostOption: 'Maximum number of options',
    submit: 'Submit',
    unfinished: 'Survey incomplete',
    tips: 'If you opt out, you\'ll lose in-game pack reward. Are you sure you want to opt out?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    submitted: 'You\'ve successfully submitted',
    thanks: 'Thank you for your support. We\'ve prepared an in-game pack for you.',
    repeat: 'You have completed the current survey, please do not submit again.',
    receive: 'Please check it out',
    back: 'Return to the game',
    lost: 'The page is lost',
    placeholder: 'Please enter',
    networkError: 'Network error, please check',
    serverError: 'The server is busy, please try again later',
    clientError: 'Client error, please try again later',
  },
  // zh-cn
  '1': {
    loading: '加载中...',
    title: '调查问卷',
    next: '下一题',
    prev: '上一题',
    mostOption: '最多选择数量',
    submit: '提交',
    unfinished: '问卷未完成',
    tips: '此时退出将无法获得游戏礼包, 确认退出吗?',
    cancel: '取消',
    confirm: '确认',
    submitted: '您已提交成功',
    thanks: '感谢您的支持, 我们为您准备了一份游戏礼包',
    repeat: '您已完成当前问卷, 请勿重复提交',
    receive: '请及时查收',
    back: '返回游戏',
    lost: '页面走丢了',
    placeholder: '请输入',
    networkError: '网络错误, 请检查',
    serverError: '服务器繁忙, 请稍后再试',
    clientError: '客户端错误, 请稍后再试',
  },
  // zh-tw
  '2': {
    loading: '加載中...',
    title: '調查問卷',
    next: '下一題',
    prev: '上一題',
    mostOption: '最多選擇數量',
    submit: '提交',
    unfinished: '問卷未完成',
    tips: '此時退出將無法獲得遊戲禮包, 確認退出嗎?',
    cancel: '取消',
    confirm: '確認',
    submitted: '您已提交成功',
    thanks: '感謝您的支持, 我們為您準備了一份遊戲禮包',
    repeat: '您已完成當前問卷, 請勿重複提交',
    receive: '請及時查收',
    back: '返回游戲',
    lost: '頁面走丟了',
    placeholder: '請輸入',
    networkError: '網絡錯誤, 請檢查',
    serverError: '服務器繁忙, 請稍後再試',
    clientError: '客戶端錯誤, 請稍後再試',
  },
  // ja
  '3': {
    loading: '読み込み中…',
    title: 'アンケート',
    next: '次へ',
    prev: '前',
    mostOption: '選択肢の最大数',
    submit: '送信',
    unfinished: 'アンケート未完了',
    tips: '完了されない場合、ゲーム内パックの報酬は受け取れません。本当にやめてもよろしいですか?',
    cancel: 'キャンセル',
    confirm: '確定',
    submitted: '送信完了',
    thanks: 'ご協力ありがとうございます。ゲーム内パックをお受け取りください。',
    repeat: '現在のアンケートは回答済みです。送信は1度のみでお願いいたします。',
    receive: 'ゲームでご確認ください',
    back: 'ゲームに戻る',
    lost: 'ページが見つかりません',
    placeholder: '入力してください',
    networkError: 'ネットワークエラーです。確認してください。',
    serverError: 'サーバーがビジー状態です。後ほど再度お試しください。',
    clientError: 'クライアントエラーです。後ほど再度お試しください。',
  },
  // ko
  '4': {
    loading: '불러오는 중...',
    title: '설문 조사',
    next: '다음',
    prev: '이전의',
    mostOption: '최대 선택 수',
    submit: '제출',
    unfinished: '설문 조사 미완료',
    tips: '참여하지 않기로 하시면 게임 내 패키지 보상을 잃게 됩니다. 정말로 참여하지 않겠습니까?',
    cancel: '취소',
    confirm: '확인',
    submitted: '제출 성공',
    thanks: '참여해주셔서 감사합니다. 감사의 뜻으로 게임 내 패키지를 준비했습니다.',
    repeat: '이미 설문 조사를 완료하셨습니다. 반복해서 제출하지 마십시오.',
    receive: '확인해 보세요',
    back: '게임으로 돌아가기',
    lost: '이 페이지는 소실되었습니다',
    placeholder: '입력하세요',
    networkError: '네트워크 오류입니다. 연결을 확인하세요.',
    serverError: '서버가 사용 중입니다. 나중에 다시 시도하세요.',
    clientError: '클라이언트 오류입니다. 나중에 다시 시도하세요.',
  },
  // ru
  '5': {
    loading: 'Загрузка...',
    title: 'Опрос',
    next: 'След.',
    prev: 'Предыдущий',
    mostOption: 'самый лучший выбор',
    submit: 'Отправить',
    unfinished: 'Опрос не завершен',
    tips: 'Отказавшись, вы потеряете награду в виде игрового набора. Вы уверены, что хотите отказаться?',
    cancel: 'Отмена',
    confirm: 'Подтвердить',
    submitted: 'Успешно отправлено',
    thanks: 'Благодарим вас за поддержку. Мы подготовили для вас игровой набор.',
    repeat: 'Вы завершили этот опрос. Пожалуйста, не отправляйте его повторно.',
    receive: 'Пожалуйста, проверьте',
    back: 'Вернуться в игру',
    lost: 'Страница потеряна',
    placeholder: 'Пожалуйста, введите',
    networkError: 'Ошибка сети. Пожалуйста, проверьте соединение.',
    serverError: 'Сервер перегружен. Пожалуйста, попробуйте позже.',
    clientError: 'Ошибка клиента. Пожалуйста, попробуйте позже.',
  },
  // vi
  '7': {
    loading: 'Đang tải...',
    title: 'Phiếu điều tra',
    next: 'Câu tiếp theo',
    prev: 'Trước',
    mostOption: 'Số lượng lựa chọn tối đa',
    submit: 'Gửi',
    unfinished: 'Chưa hoàn thành',
    tips: 'Bây giờ thoát ra sẽ không thể nhận Gói quà của game, có thoát không?',
    cancel: 'Hủy',
    confirm: 'Xác nhận',
    submitted: 'Bạn đã gửi thành công',
    thanks: 'Cảm ơn bạn đã ủng hộ, chúng tôi đã chuẩn bị cho bạn 1 phần quà hấp dẫn',
    repeat: '현재 설문지를 작성하셨습니다. 반복해서 제출하지 마십시오.',
    receive: 'Hãy kiểm nhận ngay',
    back: 'Quay lại Game',
    lost: 'Giao diện bị mất rồi',
    placeholder: 'Hãy nhập vào',
    networkError: 'Lỗi mạng, xin vui lòng kiểm tra',
    serverError: 'Máy chủ đang bận, hãy thử lại sau',
    clientError: 'Lỗi khách hàng, xin vui lòng thử lại sau',
  },
  // de
  '8': {
    loading: 'Lädt…',
    title: 'Umfrage',
    next: 'Weiter',
    prev: 'Vorherige',
    mostOption: 'Maximale Auswahlmöglichkeiten',
    submit: 'Abschicken',
    unfinished: 'Umfrage unvollständig',
    tips: 'Wenn du nicht teilnimmst, entgeht dir die In-Game-Belohnung. Willst du wirklich nicht teilnehmen?',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    submitted: 'Erfolgreich abgeschickt',
    thanks: 'Vielen Dank für deine Unterstützung. Wir haben ein In-Game-Paket für dich vorbereitet.',
    repeat: 'Du hast die aktuelle Umfrage abgeschlossen. Bitte schicke sie nicht erneut ab.',
    receive: 'Sieh es dir an',
    back: 'Zurück zum Spiel',
    lost: 'Diese Seite ist nicht mehr aufrufbar',
    placeholder: 'Bitte eingeben',
    networkError: 'Netzwerkfehler. Bitte überprüfe',
    serverError: 'Der Server ist derzeit ausgelastet, bitte versuche es später erneut.',
    clientError: 'Client-Fehler, bitte versuche es später erneut.',
  },
  // fr
  '9': {
    loading: 'Chargement…',
    title: 'Questionnaire',
    next: 'Suivant',
    prev: 'Précédent',
    mostOption: 'Nombre maximum de choix',
    submit: 'Envoyer',
    unfinished: 'Questionnaire incomplet',
    tips: 'Si vous choisissez de quitter ce questionnaire, vous perdrez le pack de récompenses en jeu. Voulez-vous vraiment quitter ?',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    submitted: 'Envoi du formulaire réussi',
    thanks: 'Merci pour votre soutien. Nous vous avons préparé un pack de récompenses en jeu pour vous remercier.',
    repeat: 'Vous avez déjà complété le questionnaire actuel. Vous ne pouvez pas le compléter à nouveau.',
    receive: 'N\'oubliez pas de le récupérer.',
    back: 'Revenir au jeu',
    lost: 'Page perdue',
    placeholder: 'Veuillez saisir ici',
    networkError: 'Erreur réseau, vérification requise',
    serverError: 'Serveur occupé, veuillez réessayer plus tard',
    clientError: 'Erreur client, veuillez réessayer plus tard',
  },
  // th
  '13': {
    loading: 'กำลังโหลด...',
    title: 'แบบสอบถาม',
    next: 'คำถามต่อไป',
    prev: 'ก่อนหน้า',
    mostOption: 'จำนวนตัวเลือกสูงสุด',
    submit: 'ส่ง',
    unfinished: 'แบบสอบถามยังไม่เสร็จสิ้น',
    tips: 'หากคุณออกจากระบบในตอนนี้ คุณจะไม่สามารถรับแพ็กเกจในเกมได้ คุณแน่ใจหรือว่าออก?',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    submitted: 'คุณได้ส่งเรียบร้อยแล้ว',
    thanks: 'ขอบคุณสำหรับการสนับสนุน เราได้เตรียมแพ็กเกจในเกมไว้ให้คุณ',
    repeat: '현재 설문지를 작성하셨습니다. 반복해서 제출하지 마십시오.',
    receive: 'โปรดตรวจรับให้ทันเวลา',
    back: 'กลับเข้าเกม',
    lost: 'หน้านี้หายไป',
    placeholder: 'กรุณาพิมพ์',
    networkError: 'ข้อผิดพลาดของเครือข่ายโปรดตรวจสอบ',
    serverError: 'เซิร์ฟเวอร์ไม่ว่า งโปรดลองใหม่ในภายหลัง',
    clientError: 'ไคลเอนต์มีข้อผิดพลาดโปรดลองใหม่ในภายหลัง',
  },
};
